import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { down } from '@thomaseckhardt/styled-breakpoints'
import config from '../utils/siteConfig'
import Layout from '../components2/Layout'
import SEO from '../components/SEO'
import Article, { VARIANT_TEXT_INTENDED } from '../modules/Article'
import { VARIANT_PORTRAIT_LANDSCAPE_INTENDED } from '../components2/Puzzle'
import JumpNav from '../components2/JumpNav'
import Teaser from '../modules/Teaser'

const Main = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  grid-template-rows: 1fr;

  > * {
    grid-column: 1 / span 16;
    min-height: 70vh;
  }

  > *:nth-child(2) {
    grid-row: 1;
    min-height: 0;
  }
`

const MainContent = styled.article`
  grid-column: 1 / span 16;
  min-height: 70vh;
`

const JumpNavContainer = styled.div`
  position: relative;
  grid-row: 1;
  grid-column: 1 / span 3;
  z-index: 1;

  ${down('laptop')} {
    display: none;
  }
`

const StyledJumpNav = styled(JumpNav)`
  position: sticky;
  top: 0;
  padding-top: ${props => props.theme.px('space.11')};
`

const Page = ({ data }) => {
  const postNode = {
    title: `Wandgestaltung - ${config.siteTitle}`,
  }

  const blockIntro = {
    leading: 10,
    variant: VARIANT_TEXT_INTENDED,
    id: `wandgestaltung`,
    headline: `Wandgestaltung`,
    lead: `Das Thema Wandgestaltung ist beim Einrichten einer Wohnung sehr wichtig. Die Wandgestaltung trägt einen großen Teil zur Wohnatmosphäre bei. Egal ob diese mit Farbe, Tapeten oder Wandbespannung stattfindet – wir beraten umfassend und geben Tipps und Anregungen!`,
  }

  const block1 = {
    leading: 10,
    variant: VARIANT_TEXT_INTENDED,
    id: `tapeten`,
    headline: `Tapeten`,
    lead: `Tapeten verleihen jedem Zimmer einen ganz persönlichen Charakter `,
    copytext: `Mit einer Tapete verleiht man seinem Geschmack eine Bühne und kann mit wenig Aufwand einen großen Effekt erzeugen. Dabei ist es egal, ob man nur eine Wand oder ein ganzes Zimmer gestaltet. Heutzutage hat man eine riesige Auswahl an Mustern und Farben. Der Digitaldruck gibt Designer unzählige Möglichkeiten ihre Ideen an die Wand zu bringen.
    Gerade bei einer solchen Fülle an Möglichkeiten, bieten wir als Fachgeschäft die entsprechende Beratung. Wir zeigen Ihnen anhand einer großen Auswahl von Tapeten und Farbmustern individuelle Möglichkeiten auf.`,
    puzzle: {
      caption: `Exklusive Tapeten von ZIMMER + RHODE und Pierre Frey`,
      variant: VARIANT_PORTRAIT_LANDSCAPE_INTENDED,
      items: [
        {
          fluid: data.tapeten1.childImageSharp.fluid
        },
        {
          fluid: data.tapeten2.childImageSharp.fluid
        }
      ]
    }
  }

  const block2 = {
    leading: 10,
    variant: VARIANT_TEXT_INTENDED,
    id: `wandbespannung`,
    headline: `Wandbespannung`,
    lead: `Wandbespannung als elegante Wandgestaltung und zugleich Raumschallverbesserung`,
    copytext: `
      <p>Eine textile Wandbespannung mit unterlegtem Polyesterfaservlies, passt sich Dank seiner Elastizität den Formen und Volumen eines Raumes an. Der Stoff umhüllt mit seiner Textur dezent die jeweilige Decke oder Wand. Auch einzelne bauliche Teile wie Säulen oder herausragende Wände werden umhüllt. Farblich akzentuiert oder fügen sich zurückhaltende in den Raum ein. Je nach Stoffauswahl bietet dessen schalldurchlässige Oberfläche perfekte akustische und technische Eigenschaften, die auch höchsten Ansprüchen genügen. Regelmäßige Anstriche der Wände entfällt. Durch die Verwendung von schmutzunempfindlichen Stoffen sind Wandbespannungen besonders langlebig und einfach in der Pflege - einfach die Wandbespannung absaugen. Ebenso raumklimatische Effekte können bei der Auswahl der Stoffe mit berücksichtigt werden, eine Aufnahme von Raumfeuchte und deren Abgabe ist den Naturfasern zugeschrieben – ein sehr angenehmer und positiver Aspekt in Betracht auf die gesunde Raumluft und das Raumklima.</p>
    `
  }

  const block3 = {
    leading: 10,
    variant: VARIANT_TEXT_INTENDED,
    id: `farben`,
    headline: `Farben und Innenwand-Techniken`,
    lead: `Das Thema Farben und Lacke hat bei uns lange Tradition.`,
    copytext: `
      <p>Bereits der Urgroßvater von Bernhard Ziegler startete die Firma als Lackfabrikation. Ein gutes Auge ist auch heute noch wichtig, die Technik dazu hat sich aber weiterentwickelt. Durch unsere computergesteurte Farbmischanlage stehen uns tausende von Farbvariationen zur Verfügung. Und sollten Sie eine Farbvorlage zur Verfügung haben, mischen wir Ihnen den passenden Farbton dazu.
      Natürlich können Sie die Wandfarben am gleichen Tag mit nach Hause nehmen. </p>
      <p>Wir führen neben den Marken Südwest, Sikkens, Jäger, Bindulin, Auto-K, Dupli-Color, Clou, Pufas, Kluthe, uvm. das Sortiment und Mischsystem von Caparol. Damit können wir unseren Kunden ein weites Spektrum an kreativen Produkten und Techniken bieten und die Möglichkeiten des Ausführenden erweitern.
      Relativ neu im Markt sind die nachhaltigen Farben von Caparol CapaGEO. Caparol hat es sich zum Ziel gesetzt Farben und Lasuren im Einklang mit der Natur herzustellen. Mit dem unbegrenzten Farbsortiment können Sie Ihrem Anspruch von gesundem und nachhaltigem Wohnen nachkommen. Wir beraten Sie gerne zu allen Themen des ökologischen Wohnens
      </p>
    `
  }

  const block4 = {
    leading: 10,
    variant: VARIANT_TEXT_INTENDED,
    id: `malerarbeiten`,
    headline: `Malerarbeiten`,
    copytext: `
      <p>Durch das Streichen und Beschichten von Flächen eröffnen sich viele Möglichkeiten der farblichen Gestaltung. Schon die Auswahl der Streichtechniken ist entscheidend für die Erzielung individueller Effekte. Durch den Auftrag von Lasuren, dem verwenden von Wischtechniken können individuelle Muster an der Wand erzeugt werden. Diese Strukturen wirken oft dynamischer als ein klassischer Anstrich.</p>
    `
  }

  const jumpNavItems = [
    {
      label: blockIntro.headline,
      href: `#${blockIntro.id}`,
      active: true
    },
    {
      label: block1.headline,
      href: `#${block1.id}`,
    },
    {
      label: block2.headline,
      href: `#${block2.id}`,
    },
    {
      label: 'Farben',
      href: `#${block3.id}`,
    },
    {
      label: block4.headline,
      href: `#${block4.id}`,
    },
  ]

  const serviceTeaser = {
    leading: 10,
    topline: `Unser Kundenservice`,
    headline: `Wir mischen Ihre Wunschfarbe`,
    action: {
      to: `/kundenservice#wandgestaltung`
    },
    image: {
      objectPosition: `100% 100%`,
      fluid: data.serviceTeaser.childImageSharp.fluid
    }
  }

  return (
    <Layout>
      <Helmet>
        <title>{`Sicht- und Sonnenschutz - ${config.siteTitle}`}</title>
      </Helmet>
      <SEO postNode={postNode} pagePath="sich-sonnenschutz" customTitle />

      <Main>
        <JumpNavContainer>
          <StyledJumpNav items={jumpNavItems} />
        </JumpNavContainer>
        <MainContent>
          <Article as="header" {...blockIntro}></Article>
          <Article as="article" {...block1}></Article>
          <Article as="article" {...block2}></Article>
          <Article as="article" {...block3}></Article>
        </MainContent>
      </Main>
      <Teaser
        as="aside"
        {...serviceTeaser} />
    </Layout>
  )
}

export default Page

export const pageQuery = graphql`
  query {
    tapeten1: file(relativePath: { eq: "wand-portrait.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    tapeten2: file(relativePath: { eq: "wand-landscape.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    serviceTeaser: file(relativePath: { eq: "service-tapeten.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
